

.App {
    max-width: 650px;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: fixed;
    /* font-family: "Hiragino Sans GB"; */
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: 'none';
    /* make scrollbar transparent */
}
body ::-webkit-scrollbar {
    width: 0;
    background: transparent;
    /* make scrollbar transparent */
}
/* @supports(margin: max(0px)) {
    .appContainer {
        padding-top: max(30px, env(safe-area-inset-left));
    }
} */

body {
    background-color: lightgray;
    display: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    font-family: Verdana, "PingFangSC-Regular", Georgia, "PingFang HK", "Hiragino Sans GB", sans-serif;
    letter-spacing: 0.02em;
    line-height: 1.7;
    font-weight: 400;
}

div {
    font-family: Verdana, "Helvetica Neue", Georgia, sans-serif;
    letter-spacing: 0.02em;
    line-height: 1.6;
    /* font-size: 16px; */
    text-align: justify;

    /* font-weight: 450; */
}

.root {
    background-color: white;
    display: relative;
    width: 100%;
    height: 100%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}

.iOS-root-20 {
    padding-top: 20px;
    height: calc(100% - 20px);
    background-color: white;
    display: relative;
    width: 100%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}


.iOS-root-35 {
    padding-top: 35px;
    height: calc(100% - 35px);
    background-color: white;
    display: relative;
    width: 100%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}

/* .weui-tabbar__item {
    height: 30px;
    padding-bottom: 0;
} */
.weui-tabbar__label {
    margin-top: -5px;
}
.weui-tabbar {
    bottom: 0;
    max-width: 650px;
    position: fixed;
    font-size: small;
    padding-bottom: 12px;
    /* height: 50px; */
    /* padding-top: 0; */
    /* height: 50px; */
    padding-top: 0;
    z-index: 500;
    background-color: white;
}

.toolbar-button {
    font-size: 1.2rem;
}

div {
    -webkit-overflow-scrolling: touch;
}
div.weui-tab__panel {
    padding-bottom: 0;
    -webkit-overflow-scrolling: auto !important;
}


blockquote.quote {
    /* border-left: 10px solid #ccc; */
    margin-left: 20px;
    color: white;
    margin: -0.8em;
    font-size: 1em;
    padding: 1em 10px;
    quotes: "\201C""\201D";
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    /* height: 1.2em; */
    -webkit-box-orient: vertical;
}
blockquote.quote:before {
    color: white;
    content: open-quote;
    font-size: 3.5em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.3em;
}

/* blockquote.quote:after {
    color: lightgrey;
    content: close-quote;
    font-size: 3em;
    line-height: 0.1em;
    margin-left: 0.25em;
    vertical-align: -0.5em;
} */
blockquote.quote p {
    display: inline;
    /* margin-left: 10px; */
}

/* .weui-tab {
    -webkit-overflow-scrolling: auto !important;
} */


/* div#infList {
    -webkit-overflow-scrolling: auto !important;
} */


/* div {
    -webkit-overflow-scrolling: auto !important;
} */

/* PhotoSwipe auto size */
.pswp img {
    max-width: none;
    object-fit: contain;
}


.pswp__button--share { display:none; }
.pswp {
    opacity: 1;
    z-index: 2000;
}
input,
textarea {
    font-size: 16px;
}

input[type="search"] {
    -webkit-appearance: textfield;
}

p, pre, strong, blockquote, code {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}